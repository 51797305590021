import { Card, Elevation, H3, Spinner } from '@blueprintjs/core';
import styles from './LoadingView.module.scss';

const LoadingView = () => (
  <div className={styles.loading}>
    <Card elevation={Elevation.TWO} className={styles.card}>
      <img src="/logo-gray-light.png" alt="GrayOS" />
      <H3>GrayOS</H3>
      <Spinner intent="primary" />
    </Card>
  </div>
);

export default LoadingView;
