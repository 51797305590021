import { MOFieldColumn } from 'components/PatientDetail/MedOnc/MOPatientPlanView/MOPatientDetailAgenda/MOAgendaTable/MOColumnOrdering';
import { APPOINTMENTS_READONLY_TABLE_ORDERING } from 'hooks/useUserStorage';
import { User } from 'types/dataTypes';

export const updateReportsColumns = (data: User['settings']) => {
  // Split patient name to first name and last name and make it sortable
  if (data[APPOINTMENTS_READONLY_TABLE_ORDERING]) {
    data[APPOINTMENTS_READONLY_TABLE_ORDERING] = [
      MOFieldColumn.PatientID,
      MOFieldColumn.LastName,
      MOFieldColumn.FirstName,
      MOFieldColumn.Date,
      MOFieldColumn.Time,
      MOFieldColumn.Duration,
      MOFieldColumn.Technique,
      MOFieldColumn.Location,
      MOFieldColumn.StaffAssigned,
      MOFieldColumn.Comments,
      MOFieldColumn.AppointmentFlags,
      MOFieldColumn.Status,
      MOFieldColumn.InsuranceID,
      MOFieldColumn.Address,
      MOFieldColumn.PhoneHome,
      MOFieldColumn.PhoneCell,
    ];
  }

  return data;
};
