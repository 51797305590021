import {
  APPOINTMENTS_READONLY_TABLE_ORDERING,
  MO_APPOINTMENTS_READONLY_TABLE_ORDERING,
  MO_REPORTS_ROW_SORTING,
  REPORTS_ROW_SORTING,
} from 'hooks/useUserStorage';
import { User } from 'types/dataTypes';

export const renameReportsSettings = (data: User['settings']) => {
  if (data[MO_REPORTS_ROW_SORTING]) {
    data[REPORTS_ROW_SORTING] = data[MO_REPORTS_ROW_SORTING];
    delete data[MO_REPORTS_ROW_SORTING];
  }

  if (data[MO_APPOINTMENTS_READONLY_TABLE_ORDERING]) {
    data[APPOINTMENTS_READONLY_TABLE_ORDERING] =
      data[MO_APPOINTMENTS_READONLY_TABLE_ORDERING];
    delete data[MO_APPOINTMENTS_READONLY_TABLE_ORDERING];
  }

  return data;
};
