import moment from 'moment';
import 'moment-business-days';
import { getUser } from 'services/auth-client';
import { User } from 'types/dataTypes';

moment.updateLocale('en', {
  week: { dow: 0 },
});
moment.updateLocale('fr', {
  week: { dow: 0 },
});

function bootstrapAppData(user?: User | any): Promise<User>;

async function bootstrapAppData(loginUser?: User) {
  const user = loginUser?.username ? loginUser : await getUser();
  if (!user) {
    return {
      username: null,
      firstName: null,
      lastName: null,
      canSeePii: false,
    };
  }

  return user;
}

export { bootstrapAppData };
