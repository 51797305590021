import { MOFieldColumn } from 'components/PatientDetail/MedOnc/MOPatientPlanView/MOPatientDetailAgenda/MOAgendaTable/MOColumnOrdering';
import { FieldColumn } from 'components/Scheduling/RadOnc/SchedulingCalendarView/ByPatient/AppointmentsTable/ColumnOrdering';
import {
  APPOINTMENTS_TABLE_COLUMN_ORDERING,
  MO_APPTS_TABLE_COLUMN_ORDERING,
} from 'hooks/useUserStorage';
import { remove, union } from 'lodash';
import { User } from 'types/dataTypes';

export const updateTableColumnOrderFields = (data: User['settings']) => {
  /**
   * We're fixing the setting key for MO apointment tables,
   * and only use the relevant fields, really only an issue
   * if youre switching the user's discipline
   * but in princible should be separate
   */

  if (
    data[MO_APPTS_TABLE_COLUMN_ORDERING] ||
    data[APPOINTMENTS_TABLE_COLUMN_ORDERING]
  ) {
    data[MO_APPTS_TABLE_COLUMN_ORDERING] = remove(
      data[MO_APPTS_TABLE_COLUMN_ORDERING] ??
        data[APPOINTMENTS_TABLE_COLUMN_ORDERING],
      // time was in the p1 table ordering
      MOFieldColumn.Time
    );
  }

  // RO add wave, care plan, and status
  if (data[APPOINTMENTS_TABLE_COLUMN_ORDERING]) {
    data[APPOINTMENTS_TABLE_COLUMN_ORDERING] = union(
      data[APPOINTMENTS_TABLE_COLUMN_ORDERING],
      [
        // previous default fields for good measure
        FieldColumn.Technique,
        FieldColumn.Duration,
        FieldColumn.Location,
        FieldColumn.StaffAssigned,
        FieldColumn.Comments,
        FieldColumn.Accessories,
        FieldColumn.AppointmentFlags,
        FieldColumn.Wave,
        // new fields
        FieldColumn.CarePlan,
        FieldColumn.Status,
      ]
    );
  }

  // MO add care plan and status
  if (data[MO_APPTS_TABLE_COLUMN_ORDERING]) {
    data[MO_APPTS_TABLE_COLUMN_ORDERING] = union(
      remove(data[MO_APPTS_TABLE_COLUMN_ORDERING], FieldColumn.Wave),
      [
        // previous default fields for good measure
        MOFieldColumn.Technique,
        MOFieldColumn.Duration,
        MOFieldColumn.Location,
        MOFieldColumn.StaffAssigned,
        MOFieldColumn.Comments,
        MOFieldColumn.Accessories,
        MOFieldColumn.AppointmentFlags,
        // new fields
        MOFieldColumn.CarePlan,
        MOFieldColumn.Status,
      ]
    );
  }

  // MO dashboard remove patient info

  return data;
};
