import { OverlayToaster, Toast2 } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { useRegisterSW } from 'virtual:pwa-register/react';
import Debug from 'debug';

interface ReloadPromptProps {}

const debug = new Debug('grayos-sw');

const ReloadPrompt = (props: ReloadPromptProps) => {
  const { t } = useTranslation();
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      debug('SW Registered: ', r);

      if (r) {
        // On reload, if there is a waiting worker, then we need to refresh the page
        // The drawback is the user will lose the current state for other tabs
        // and the page will essentially refresh twice
        if (r.waiting && r.active) {
          updateServiceWorker(true);
        }

        r.update();

        setInterval(() => {
          debug('checking for updates');
          r.update();
        }, 30 * 1000);
      }
    },
    onNeedRefresh() {
      debug('SW needs refresh');
    },
    onRegisterError(error) {
      debug('SW registration error', error);
    },
  });

  if (needRefresh) {
    return (
      <OverlayToaster maxToasts={1} position="bottom" canEscapeKeyClear={false}>
        <Toast2
          isCloseButtonShown={false}
          timeout={0}
          intent="primary"
          message={t('new-version')}
          action={{
            text: t('overview.refresh'),
            onClick: () => {
              updateServiceWorker(true);
              setNeedRefresh(false);
            },
          }}
        />
      </OverlayToaster>
    );
  }

  return null;
};

export default ReloadPrompt;
