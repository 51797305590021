import React from 'react';
import LoadingView from 'components/LoadingView';
import { Route, Switch } from 'react-router-dom';

const AppAuthenticated = React.lazy(() => import('./AppAuthenticated'));
const LoginPage = React.lazy(() => import('./LoginPage'));

function App() {
  return (
    <React.Suspense fallback={<LoadingView />}>
      <Switch>
        <Route path="/login" exact component={LoginPage} />
        <Route path="*" default component={AppAuthenticated} />
      </Switch>
    </React.Suspense>
  );
}

// eslint-disable-next-line no-console
console.log(
  '   _____                    ____   _____ \n' +
    '  / ____|                  / __ \\ / ____|\n' +
    ' | |  __ _ __ __ _ _   _  | |  | | (___  \n' +
    " | | |_ | '__/ _` | | | | | |  | |\\___ \\ \n" +
    ' | |__| | | | (_| | |_| | | |__| |____) |\n' +
    '  \\_____|_|  \\__,_|\\__, |  \\____/|_____/ \n' +
    '                    __/ |                \n' +
    '                   |___/   ' +
    `v${
      import.meta.env.VITE_NOETHER_VERSION ||
      import.meta.env.VITE_GRAY_OS_ENV ||
      'X.X.X-development'
    }` +
    '\n Build date: __DATE__'
);

export default App;
