import {
  Button,
  ButtonProps,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverProps,
} from '@blueprintjs/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

// value should correspond a translation key
export enum MOFieldColumn {
  PatientName = 'patient',
  PatientID = 'patId',
  FirstName = 'first-name',
  LastName = 'last-name',
  Date = 'date',
  Time = 'time',
  Technique = 'technique',
  Duration = 'duration',
  Location = 'location',
  StaffAssigned = 'staff-assigned',
  Comments = 'comments',
  Accessories = 'accessories',
  AppointmentFlags = 'appointment-flags',
  CarePlan = 'care-plan',
  Status = 'status',
  InsuranceID = 'insurance-id',
  Address = 'address',
  PhoneHome = 'phone-home',
  PhoneCell = 'phone-cell',
  CancellationRisk = 'cancellation-risk',
  CycleAndDay = 'activity-day-and-cycle',
}

export const MO_APPTS_TABLE_DEFAULT_ORDER = [
  MOFieldColumn.Technique,
  MOFieldColumn.Duration,
  MOFieldColumn.Location,
  MOFieldColumn.StaffAssigned,
  MOFieldColumn.Comments,
  MOFieldColumn.AppointmentFlags,
  MOFieldColumn.Accessories,
  MOFieldColumn.CarePlan,
  MOFieldColumn.Status,
];

interface ColumnOrderingProps {
  columnOrder: MOFieldColumn[];
  setColumnOrder: (columnOrder: MOFieldColumn[]) => void;
  popoverProps?: PopoverProps;
  buttonProps?: ButtonProps;
  disabledColumns?: MOFieldColumn[];
}

const MOColumnOrdering = ({
  columnOrder,
  setColumnOrder,
  popoverProps,
  buttonProps,
  disabledColumns,
}: ColumnOrderingProps) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () =>
      columnOrder.map((col) => ({
        id: col,
        label: t(`scheduling.appointment-card.${col}`, col),
      })),
    [columnOrder, t]
  );

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = columnOrder.findIndex((act) => act === active.id);
      const newIndex = columnOrder.findIndex((act) => act === over.id);

      const reorderedColumns = arrayMove(columnOrder, oldIndex, newIndex);

      setColumnOrder(reorderedColumns);
    }
  };

  const disabledColumnSet = new Set(disabledColumns);

  return (
    <Popover
      content={
        <Menu>
          <MenuDivider title={t('scheduling.agenda.field-order')} />
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext
              items={columnOrder}
              strategy={verticalListSortingStrategy}
            >
              {columns.map((column) => (
                <DraggableItem
                  key={column.id}
                  id={column.id}
                  label={column.label}
                  disabled={disabledColumnSet.has(column.id)}
                />
              ))}
            </SortableContext>
          </DndContext>
        </Menu>
      }
      lazy
      {...popoverProps}
    >
      <Button minimal icon="split-columns" {...buttonProps} />
    </Popover>
  );
};

export const DraggableItem = ({
  id,
  label,
  disabled,
}: {
  id: string;
  label: React.ReactNode;
  disabled?: boolean;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    outline: 0,
    cursor: 'move',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <MenuItem
        key={id}
        text={label}
        icon="drag-handle-vertical"
        disabled={disabled}
      />
    </div>
  );
};

export default MOColumnOrdering;
