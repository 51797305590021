import React, { useCallback, useMemo, useState } from 'react';
import { bootstrapAppData } from 'services/bootstrap';
import * as authClient from 'services/auth-client';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationState, MetaDataTag, Team, User } from 'types/dataTypes';
import {
  USER_SETTINGS_LATEST_VERSION,
  migrate,
} from 'store/migrations/user-settings/migrate';
import debug from 'debug';

export interface AppData {
  user: User;
  login: (form?: {
    username: string;
    password: string;
  }) => Promise<User | void>;
  logout: () => void;
  team?: Team;
  isLoading: boolean;
  teamMetaTags: Map<string, MetaDataTag>;
}

const defaultValue = {
  user: {
    id: 0,
    username: null,
    firstName: null,
    lastName: null,
    canSeePii: false,
    canChangeTeams: false,
    canModifyRoster: false,
    isStaff: false,
    settings: { version: 0 },
    canRunJobs: false,
    canViewJobs: false,
  },
  login: () => Promise.resolve(),
  logout: () => undefined,
  isLoading: false,
  teamMetaTags: new Map(),
};

const AuthContext = React.createContext<AppData>(defaultValue);
AuthContext.displayName = 'AuthContext';

function AuthProvider(props) {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [user, setUser] = useState<User>(defaultValue.user);
  const [isLoading, setIsLoading] = useState(false);

  const login = useCallback(
    async (credentials?: { username: string; password: string }) => {
      setIsLoading(true);

      // Pass form login credentials, otherwise try to login using the saved session
      return (
        credentials ? authClient.login(credentials) : authClient.getUser()
      )
        .then(async (user: User) => {
          await bootstrapAppData(user);

          if ((user.settings.version ?? 0) < USER_SETTINGS_LATEST_VERSION) {
            const migratedSettings = migrate(user.settings);
            user.settings = migratedSettings;

            authClient.updateSetting({ ...migratedSettings });
          }

          if (user.isStaff && !localStorage.getItem('debug')) {
            debug.enable('scheduling:event*');
          }

          setUser(user);
          history.replace(location.state?.path ?? '/', {}); // clear the state
          return user;
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [history, location]
  );

  const logout = () => {
    authClient.logout().then(() => {
      history.push('/login', {
        logout: true,
        path: location.pathname + location.search,
      });
      setUser(defaultValue.user);
    });
  };

  const teamMetaTags = useMemo(
    () =>
      new Map(
        user.team?.defaultParameters.metaTags?.map((tag) => [tag.name, tag]) ||
          []
      ),
    [user.team]
  );

  const value = {
    user,
    login,
    logout,
    team: user.team,
    teamMetaTags,
    isLoading,
  };

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
