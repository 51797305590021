import { Appointment, AppointmentStatus } from 'types/dataTypes';

export const DEFAULT_APPT_STATUS_FILTER: Record<AppointmentStatus, boolean> = {
  [AppointmentStatus.PROPOSED]: true,
  [AppointmentStatus.PENDING]: true,
  [AppointmentStatus.BOOKED]: true,
  [AppointmentStatus.ARRIVED]: true,
  [AppointmentStatus.FULFILLED]: true,
  [AppointmentStatus.CANCELLED]: false,
  [AppointmentStatus.ENTERED_IN_ERROR]: false,
  [AppointmentStatus.NOSHOW]: false,
  [AppointmentStatus.CHECKED_IN]: true,
  [AppointmentStatus.WAITLIST]: true,
};

export const INACTIVE_APPT_STATUSES = {
  [AppointmentStatus.CANCELLED]: false,
  [AppointmentStatus.ENTERED_IN_ERROR]: false,
  [AppointmentStatus.NOSHOW]: false,
};

/**
 * Sometimes they delete sometimes they cancel
 * Nevertheless this checks if your appointment is active
 */
const isActiveAppointment = <TAppt extends Pick<Appointment, 'aptStatus'>>(
  appointment: TAppt
) => INACTIVE_APPT_STATUSES[appointment.aptStatus] === undefined;

export default isActiveAppointment;
