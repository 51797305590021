import { MOFieldColumn } from 'components/PatientDetail/MedOnc/MOPatientPlanView/MOPatientDetailAgenda/MOAgendaTable/MOColumnOrdering';
import { APPOINTMENTS_READONLY_TABLE_ORDERING } from 'hooks/useUserStorage';
import { User } from 'types/dataTypes';

export const addMoActivityColumn = (data: User['settings']) => {
  // Split patient name to first name and last name and make it sortable
  if (data[APPOINTMENTS_READONLY_TABLE_ORDERING]) {
    const index = (
      data[APPOINTMENTS_READONLY_TABLE_ORDERING] as string[]
    ).indexOf(MOFieldColumn.Technique);

    data[APPOINTMENTS_READONLY_TABLE_ORDERING] = [
      ...data[APPOINTMENTS_READONLY_TABLE_ORDERING].slice(0, index + 1),
      MOFieldColumn.CycleAndDay,
      ...data[APPOINTMENTS_READONLY_TABLE_ORDERING].slice(index + 1),
    ];
  }

  return data;
};
