import styles from './App.module.scss';
import { Button, NonIdealState } from '@blueprintjs/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface AppErrorProps {
  error: Error;
}

const AppError: React.FC<AppErrorProps> = (props: AppErrorProps) => {
  const { t } = useTranslation();
  const handleReload = () => {
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) =>
        Promise.all(
          registrations.map((registration) => registration.unregister())
        )
      )
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <div className={clsx(styles.appError)}>
      <NonIdealState
        icon="error"
        iconMuted={false}
        title={t('error.title')}
        layout="horizontal"
        description={
          <div>
            <p>
              {t('error.error')}: {props.error.message}
            </p>
            <p>{t('error.message')}</p>
          </div>
        }
        action={
          <Button onClick={handleReload} outlined>
            {t('error.reload')}
          </Button>
        }
      />
    </div>
  );
};

export default AppError;
