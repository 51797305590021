import history from 'app-history';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'utilities/snakecase-keys';
import getCookie from './getCookie';

export const ADMIN_URL = window?.location
  ? window.location.protocol +
    '//' +
    window.location.host.replace(':3000', '') +
    '/admin'
  : 'http://localhost/admin';

type options = {
  noCamelCase?: boolean;
  noSnakeCase?: boolean;
  stopPaths?: string[];
  exclude?: Array<string | RegExp>;
};
type clientFunc = (endpoint: string, config?: any, options?: options) => any;

const client: clientFunc = async (
  endpoint,
  { body, ...customConfig } = {},
  options
) => {
  const headers = { 'content-type': 'application/json' };

  const csrfToken = getCookie('csrftoken');

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
      'X-CSRFToken': csrfToken,
    },
  };

  if (body) {
    config.body = JSON.stringify(
      options?.noSnakeCase
        ? body
        : snakecaseKeys(body, {
            deep: true,
            stopPaths: options?.stopPaths,
            exclude: options?.exclude,
          })
    );
  }

  const serializeErrors = (errors) => {
    let serializedErrors = '';

    /* eslint-disable-next-line */
    Object.entries(errors).map((error) => {
      if (error[0] === 'detail') {
        serializedErrors += `${error[1]} \n`;
      } else {
        serializedErrors += `${error[0]}: ${error[1]} \n`;
      }
    });

    return serializedErrors;
  };

  try {
    const response = await fetch(`/api/${endpoint}`, config);

    if (!response.ok) {
      const errors = await response.json();

      if (
        response.status === 401 &&
        !window.location.pathname.startsWith('/login')
      ) {
        // Session token expired, let's reauthenticate the user
        // Checking against `/login` to avoid infinite loops
        history.push('/login', {
          path: window.location.pathname + window.location.search,
          logout: true,
        });
      }

      throw Object.assign(new Error(serializeErrors(errors)), {
        statusCode: response.status,
      });
    }

    if (response.status === 204) {
      return Promise.resolve();
    }

    const jsoned = await response.json();
    return options?.noCamelCase
      ? jsoned
      : camelcaseKeys(jsoned, {
          deep: true,
          stopPaths: options?.stopPaths,
          exclude: options?.exclude,
        });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const clientV2 =
  (backendModule: string): clientFunc =>
  (endpoint, config, options) =>
    client(`v2/${backendModule}/${endpoint}`, config, options);

export default client;
