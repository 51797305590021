import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { DEFAULT_ALLOWED_APT_STATUSES } from 'constants/defaults';
import {
  DEFAULT_CLOSING_HOUR,
  DEFAULT_OPENING_HOUR,
  DEFAULT_SLOT_DURATION,
} from 'constants/operatingHoursAndSlotDuration';
import {
  APPT_STATUS_FILTER__CALENDARS,
  APPT_STATUS_FILTER__PATIENT_DETAIL,
} from 'hooks/useUserStorage';
import { updateSetting } from 'services/auth-client';
import { RootState } from 'store/configure-store';
import { OperatingHours, User } from 'types/dataTypes';
import { AppointmentStatusFilters } from 'utilities/appointment.utils';
import { DEFAULT_APPT_STATUS_FILTER } from 'utilities/is-active-appointment';

const initialState = {
  user: {} as User,
  settings: {} as User['settings'],
};

type SettingPayload = { key: string; value: any };

export const updateUserSetting = createAsyncThunk(
  'user/updateUserSetting',
  async ({ key, value }: SettingPayload) =>
    updateSetting({
      [key]: value,
    })
);

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.settings = action.payload.settings;
    },
    updateUserSettings: (state, action: PayloadAction<User['settings']>) => {
      state.settings = action.payload;
    },
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserSetting.pending, (state, action) => {
      // Optimistically update the setting
      if (action.meta.arg) {
        const { key, value } = action.meta.arg;
        state.settings[key] = value;
      }
    });

    // we could sync it again after the thunk is fulfilled
    // however if the user is toggling too fast there is potential that
    // a fulfill be sandwiched and reset the tail change for a second
  },
});

export const { loadUser, updateUserSettings, logout } = user.actions;

export const DEFAULT_TEAM_PARAMS = {
  exemptLocations: [],
  metaTags: [],
  slotDuration: DEFAULT_SLOT_DURATION,
  defaultAcuity: 1,
  defaultPharmacyAcuity: 1,
  maxCommentLength: 60,
  openTime: DEFAULT_OPENING_HOUR,
  closeTime: DEFAULT_CLOSING_HOUR,
  createLabAppointments: true,
  baseCalendarResources: [],
  canRemoveFlags: true,
  statusColors: {},
  allowedStatuses: DEFAULT_ALLOWED_APT_STATUSES,
  excludeNullStaff: true,
  excludeOutsideRoster: true,
  minTxDelay: 0,
  calendarTagsWhitelist: [],
  quickBookingFromPatientDetails: false,
};

export const getUser = (state: RootState) => state.user;
export const getTeam = createSelector(
  getUser,
  (state) => state.user.team! ?? {}
);
export const getTeamParameters = createSelector(getTeam, (team) => ({
  ...DEFAULT_TEAM_PARAMS,
  ...team?.defaultParameters,
}));
export const getUserSetting = createSelector(
  getUser,
  (_: RootState, key: string) => key,
  (state, key) => state.settings[key]
);

export const getDefaultSlotDuration = createSelector(
  getTeamParameters,
  (teamParams) => teamParams.slotDuration ?? DEFAULT_SLOT_DURATION
);

export const getDefaultAcuity = createSelector(
  getTeamParameters,
  (teamParams) => teamParams.defaultAcuity ?? 1
);

export const getMaxCommentLength = createSelector(
  getTeamParameters,
  (teamParams) => teamParams.maxCommentLength ?? 60
);

export const getTeamDiscipline = createSelector(
  getTeam,
  (team) => team.discipline ?? ''
);

export const getOperatingHours = createSelector(
  getTeamParameters,
  (teamParams): OperatingHours => ({
    openTime: teamParams.openTime ?? DEFAULT_OPENING_HOUR,
    closeTime: teamParams.closeTime ?? DEFAULT_CLOSING_HOUR,
  })
);

export const getAptStatusFilters = createSelector(
  (state: RootState) => getUserSetting(state, APPT_STATUS_FILTER__CALENDARS),
  (settings: AppointmentStatusFilters) => ({
    ...DEFAULT_APPT_STATUS_FILTER,
    ...settings,
  })
);

export const getPatientDetailAptStatusFilters = createSelector(
  (state: RootState) =>
    getUserSetting(state, APPT_STATUS_FILTER__PATIENT_DETAIL),
  (settings: AppointmentStatusFilters) => ({
    ...DEFAULT_APPT_STATUS_FILTER,
    ...settings,
  })
);

export default user.reducer;
