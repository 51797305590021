import {
  Activity,
  MOTagAssignment,
  PatientBadge,
  SchedulingAccessory,
  SchedulingAppointmentFlag,
  SchedulingResource,
  SchedulingStaffMember,
  TagBalanceRule,
  Team,
  User,
} from 'types/dataTypes';
import client from './rest-api';

const userStopPaths = [
  // use stopPaths on data that can contain user-specified keys
  'team.default_parameters.technique_colors',
  'team.default_parameters.status_colors',
  'settings',
];

export const getUser = (): Promise<User> =>
  client('me/', {}, { stopPaths: userStopPaths });

export const login = ({ username, password }): Promise<User> =>
  client('me/', { body: { username, password } }, { stopPaths: userStopPaths });

export const logout = (): Promise<{}> => client('me/', { method: 'DELETE' });

export const updateSetting = (
  settings: Partial<User['settings']>
): Promise<User['settings']> =>
  client(
    'me/settings/',
    { method: 'PATCH', body: { ...settings } },
    { noCamelCase: true, noSnakeCase: true }
  );

interface Holidays {
  id: string;
  date: string;
  name: string;
}

export const getHolidays = (): Promise<Holidays[]> =>
  client('scheduler/holidays/');

export const getTeams = (): Promise<Team[]> => client('teams/');

export const changeTeams = (teamId: Team['id']) =>
  client('me/team-switch/', { method: 'POST', body: { teamId } });

export const bootstrapGrayOS = (): Promise<{
  team: Team;
  accessories: SchedulingAccessory[];
  flags: SchedulingAppointmentFlag[];
  activities: Activity[];
  resources: SchedulingResource[];
  staff: SchedulingStaffMember[];
  tagAssignments: MOTagAssignment[];
  holidays: Holidays[];
  teams: Team[];
  patientBadges: PatientBadge[];
  tagBalanceRules: TagBalanceRule[];
}> => client('bootstrap/');
