import Debug from 'debug';
import { User } from 'types/dataTypes';
import { updateTableColumnOrderFields } from './1-update-table-col-order-fields';
import { fixMOTableTime } from './2-fix-mo-table-time-dupe';
import { renameReportsSettings } from './3-rename-reports-settings';
import { updateReportsColumns } from './4-update-reports-columns';
import { addMoActivityColumn } from './5-add-mo-activity-column';

const debug = Debug('migrate:user-settings');

export const MIGRATIONS = {
  1: updateTableColumnOrderFields,
  2: fixMOTableTime,
  3: renameReportsSettings,
  4: updateReportsColumns,
  5: addMoActivityColumn,
};

export const USER_SETTINGS_LATEST_VERSION = 5;

/**
 * Idea from https://blog.pragmatists.com/data-persistence-in-evolving-web-application-efbf89ee7c19
 */
export function migrate(data: User['settings']) {
  const updatedData = Object.keys(MIGRATIONS).reduce(
    (data, version) => {
      const fn = MIGRATIONS[version];

      const currentVersion = data.version ?? 0;

      if (currentVersion < +version) {
        debug(`Migrating from v${currentVersion} to v${version}`, {
          oldData: { ...data },
        });

        data = fn(data);
        debug(`Migrated v${version}`, { newData: data });
        data.version = +version;
      }

      return data;
    },
    { ...data }
  );

  return updatedData;
}
