import mapObj from 'map-obj';
import QuickLru from 'quick-lru';
import snakeCase from 'lodash/snakeCase';

const has = (array, key) =>
  array.some((x) => {
    if (typeof x === 'string') {
      return x === key;
    }

    x.lastIndex = 0;
    return x.test(key);
  });

const cache = new QuickLru({ maxSize: 100000 });

// Reproduces behavior from `map-obj`
const isObject = (value) =>
  typeof value === 'object' &&
  value !== null &&
  !(value instanceof RegExp) &&
  !(value instanceof Error) &&
  !(value instanceof Date);

const snakeCaseConvert = (input, options) => {
  if (!isObject(input)) {
    return input;
  }

  options = {
    deep: false,
    ...options,
  };

  const { exclude, stopPaths, deep } = options;

  const stopPathsSet = new Set(stopPaths);

  const makeMapper = (parentPath) => (key, value) => {
    if (deep && isObject(value)) {
      const path = parentPath === undefined ? key : `${parentPath}.${key}`;

      if (!stopPathsSet.has(path)) {
        value = mapObj(value, makeMapper(path) as any);
      }
    }

    if (!(exclude && has(exclude, key))) {
      const cacheKey = key;

      if (cache.has(cacheKey)) {
        key = cache.get(cacheKey);
      } else {
        const returnValue = snakeCase(key);

        if (key.length < 100) {
          // Prevent abuse
          cache.set(cacheKey, returnValue);
        }

        key = returnValue;
      }
    }

    return [key, value];
  };

  return mapObj(input, makeMapper(undefined) as any);
};

const snakecaseKeys = (input, options) => {
  if (Array.isArray(input)) {
    return Object.keys(input).map((key) =>
      snakeCaseConvert(input[key], options)
    );
  }

  return snakeCaseConvert(input, options);
};

export default snakecaseKeys;
